import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/ubuntu/flomesh.io-main/src/layouts/Fixed/DocMdx.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "核心"
    }}>{`核心`}</h2>
    <ul>
      <li parentName="ul">{`对象生命周期管理重大变革，解决核心崩溃问题`}</li>
      <li parentName="ul">{`代码库存储支持 LevelDB`}</li>
      <li parentName="ul">{`新的管理代理模式`}</li>
      <li parentName="ul">{`启动时代码库下载失败重试`}</li>
    </ul>
    <h2 {...{
      "id": "pipyjs"
    }}>{`PipyJS`}</h2>
    <ul>
      <li parentName="ul">{`完全实现标准 JavaScript 数组、数学和日期API（无迭代器和时区）`}</li>
      <li parentName="ul">{`符合 JavaScript 标准的字符串 API，具有 UTF-32 语义和 UTF-8 内部表示`}</li>
    </ul>
    <h2 {...{
      "id": "api"
    }}>{`API`}</h2>
    <ul>
      <li parentName="ul">{`用于监控的指标和统计 API，内置 Prometheus Exposition 格式支持`}</li>
      <li parentName="ul">{`新的 `}<inlineCode parentName="li">{`URLSearchParams`}</inlineCode>{` API`}</li>
      <li parentName="ul">{`支持生成任意二进制数据的数据 API`}</li>
      <li parentName="ul">{`在 `}<inlineCode parentName="li">{`XML.decode()`}</inlineCode>{` 和 `}<inlineCode parentName="li">{`XML.encode()`}</inlineCode>{` 中处理 CDATA`}</li>
      <li parentName="ul">{`支持通过编程 `}<inlineCode parentName="li">{`pipy.exit()`}</inlineCode>{` 来关闭`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`console.log()`}</inlineCode>{` 以 JSON 格式打印对象`}</li>
    </ul>
    <h2 {...{
      "id": "过滤器"
    }}>{`过滤器`}</h2>
    <h3 {...{
      "id": "通用"
    }}>{`通用`}</h3>
    <ul>
      <li parentName="ul">{`入站连接支持读/写超时`}</li>
      <li parentName="ul">{`出站连接支持读/写/连接超时`}</li>
      <li parentName="ul">{`支持信号驱动的任务`}</li>
      <li parentName="ul">{`支持监听地址绑定`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`use`}</inlineCode>{` 过滤器支持模块（插件）链和往返过滤器路径`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`mux`}</inlineCode>{` 过滤器支持使用对象作为弱键来共享会话`}</li>
      <li parentName="ul">{`负载均衡支持动态的不健康目标缓存`}</li>
    </ul>
    <h3 {...{
      "id": "http"
    }}>{`HTTP`}</h3>
    <ul>
      <li parentName="ul">{`没有流量控制的基础 HTTP/2 支持`}</li>
      <li parentName="ul">{`HTTP 编解码标准一致性提升`}</li>
      <li parentName="ul">{`支持 HTTP 隧道和 WebSocket`}</li>
      <li parentName="ul">{`重新加载时主动关闭 HTTP 持久连接`}</li>
      <li parentName="ul">{`HTTP/1 消息过大时自动分块`}</li>
    </ul>
    <h3 {...{
      "id": "tls"
    }}>{`TLS`}</h3>
    <ul>
      <li parentName="ul">{`工作节点和代码库节点间支持 mTLS `}</li>
      <li parentName="ul">{`新增 `}<inlineCode parentName="li">{`handleTLSClientHello`}</inlineCode>{` 过滤器，用于提取 TLS 客户端握手信息`}</li>
      <li parentName="ul">{`新增 `}<inlineCode parentName="li">{`detectProtocol`}</inlineCode>{` 过滤器，用于区分 TLS 和普通 TCP 连接`}</li>
      <li parentName="ul">{`TLS 性能提升`}</li>
      <li parentName="ul">{`OpenSSL 库升级到 1.1.1-m`}</li>
      <li parentName="ul">{`编译时可选外部 OpenSSL 库`}</li>
    </ul>
    <h3 {...{
      "id": "socks"
    }}>{`SOCKS`}</h3>
    <ul>
      <li parentName="ul">{`客户端侧新增 `}<inlineCode parentName="li">{`connectSOCKS`}</inlineCode>{` 过滤器`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`proxySOCKS`}</inlineCode>{` 过滤器改名为 `}<inlineCode parentName="li">{`acceptSOCKS`}</inlineCode></li>
    </ul>
    <h3 {...{
      "id": "mqtt"
    }}>{`MQTT`}</h3>
    <ul>
      <li parentName="ul">{`简单的 MQTT 编解码过滤器，用于提取 MQTT 数据包`}</li>
    </ul>
    <h3 {...{
      "id": "gui"
    }}>{`GUI`}</h3>
    <ul>
      <li parentName="ul">{`脚本编辑器支持 IntelliSense`}</li>
      <li parentName="ul">{`从函数范围内的脚本生成管道图`}</li>
      <li parentName="ul">{`包含完整的教程和本地化的内置文档`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      